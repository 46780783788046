import type { FC } from 'react'
import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import type { TSkeletons } from '@frontend/pole-ui/lib/components/Skeleton'
import { useGetByOptions } from '@/api/kubik/credit/hooks/useGetByOptions'
import { Skeleton } from '@/components/ui'
import type { IFinancingLoanProducts } from '@/logic/financing/interfaces'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import type { EFinancingProductId } from '@/types'
import { LOAN_PRODUCTS } from '../../constants'
import { LoanProduct, NoSuitableProducts } from './components'
import { messages } from './messages'
import type { ILoanProductsListProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const SKELETONS: TSkeletons = Array(5).fill({ height: 250 })

const LoanProductsList: FC<ILoanProductsListProps> = props => {
  const [activeCollapsingProductId, setActiveCollapsingProductId] = useState<Maybe<EFinancingProductId>>(null)
  const { options, onResetFilters } = props

  const { userRegion } = useSelector(mapState)
  const { mutate, data, isLoading, isIdle } = useGetByOptions()

  useEffect(() => {
    mutate({ options, regionCode: userRegion.code })
  }, [mutate, options, userRegion.code])

  const loanProducts = data?.data?.data as IFinancingLoanProducts | undefined // incorrect openapi schema

  const compatibleProductIds = loanProducts?.compatibleProductIds ?? []
  const otherProductIds = loanProducts?.otherProductsIds ?? []

  return (
    <div className="loanProductsList">
      <Skeleton skeletons={SKELETONS} isLoading={isLoading || isIdle}>
        {compatibleProductIds.length > 0 ? (
          <div className="loanProductsList__containerOfProducts">
            {otherProductIds.length > 0 && <h3 className="loanProductsList__compatibleLabel">{messages.compatible}</h3>}
            {compatibleProductIds.map(
              name =>
                !LOAN_PRODUCTS[name]?.isHidden && (
                  <LoanProduct
                    product={LOAN_PRODUCTS[name]}
                    key={name}
                    activeCollapsingProductId={activeCollapsingProductId}
                    setActiveCollapsingProductId={setActiveCollapsingProductId}
                  />
                ),
            )}
          </div>
        ) : (
          <NoSuitableProducts onResetFilters={onResetFilters} />
        )}
        {otherProductIds.length > 0 && (
          <>
            <div className="loanProductsList__othersLabel">
              <h3 className="loanProductsList__othersLabel__text">{messages.others}</h3>
              {otherProductIds.map(name => (
                <LoanProduct
                  product={LOAN_PRODUCTS[name]}
                  key={name}
                  activeCollapsingProductId={activeCollapsingProductId}
                  setActiveCollapsingProductId={setActiveCollapsingProductId}
                />
              ))}
            </div>
          </>
        )}
      </Skeleton>
    </div>
  )
}

export default memo(LoanProductsList)
