import type { FC } from 'react'
import React, { memo } from 'react'
import classNames from 'classnames'
import { LoanProductCollapsing } from './components/LoanProductCollapsing'
import { LoanProductConditions, LoanProductFooter, LoanProductHeader, LoanProductImage } from './components'
import type { ILoanProductProps } from './types'
import './styles.scss'

const LoanProduct: FC<ILoanProductProps> = props => {
  const { product, activeCollapsingProductId, setActiveCollapsingProductId } = props

  if (!product) return null

  const isShowCollapsingBlock = activeCollapsingProductId === product.productId

  const { name, conditions, brand } = product

  return (
    <div className="loanProduct" key={name}>
      <div className="loanProduct elevatableBlock">
        <div className={classNames('loanProduct__wrapper', { 'loanProduct--active': isShowCollapsingBlock })}>
          <div className="loanProduct__anchor" id={product.name} />
          <LoanProductImage product={product} />
          <div className="loanProduct__data">
            <LoanProductHeader product={product} />
            <LoanProductConditions conditions={conditions} brand={brand} tooltip={product.tooltip} />
            <LoanProductFooter
              product={product}
              isShowCollapsingBlock={isShowCollapsingBlock}
              setIsShowCollapsingBlock={setActiveCollapsingProductId}
            />
          </div>
        </div>
        {isShowCollapsingBlock && <LoanProductCollapsing product={product} />}
      </div>
    </div>
  )
}

export default memo(LoanProduct)
