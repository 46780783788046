import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { EAgriculturalProductsRoute, EFinancingOfferRoute, EFinancingOfferType, EFinancingProductId, ELoanProductName } from '@/types'
import { messages } from './messages'
import type { ILoanProduct, ILoanProductBadge, ILoanProductBrand } from './types'
import { EConditionLabel, EConditionValue, ELoanProductActionType, ELoanProductBadgeType, ELoanProductBrand } from './types'

const FINANCING_PRODUCT_BADGE: Record<ELoanProductBadgeType, ILoanProductBadge> = {
  [ELoanProductBadgeType.DISCOUNT_5PCT]: { text: ELoanProductBadgeType.DISCOUNT_5PCT },
  [ELoanProductBadgeType.HIGH_APPROVAL_RATE]: { text: ELoanProductBadgeType.HIGH_APPROVAL_RATE, color: EChipModifier.LIGHT_ORANGE },
  [ELoanProductBadgeType.THREE_MINUTES]: { text: ELoanProductBadgeType.THREE_MINUTES, color: EChipModifier.LIGHT_ORANGE },
  [ELoanProductBadgeType.INDUSTRY_SOLUTION]: { text: ELoanProductBadgeType.INDUSTRY_SOLUTION, color: EChipModifier.LIGHT_ORANGE },
  [ELoanProductBadgeType.DISCOUNTED_RATES]: { text: ELoanProductBadgeType.DISCOUNTED_RATES, color: EChipModifier.LIGHT_ORANGE },
  [ELoanProductBadgeType.WITHOUT_AUDIT]: { text: ELoanProductBadgeType.WITHOUT_AUDIT, color: EChipModifier.LIGHT_ORANGE },
  [ELoanProductBadgeType.SMALLEST_OVERPAYMENT]: { text: ELoanProductBadgeType.SMALLEST_OVERPAYMENT, color: EChipModifier.LIGHT_ORANGE },
}

const LOAN_PRODUCT_BRAND_LOGO_FOLDER = 'loanProducts/brand/'

export const LOAN_PRODUCT_BRAND_BY_NAME: Readonly<Record<ELoanProductBrand, ILoanProductBrand>> = {
  [ELoanProductBrand.AGROINTEGRATOR]: {
    name: ELoanProductBrand.AGROINTEGRATOR,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}agrointegrator`,
  },
  [ELoanProductBrand.SBER_BUSINESS]: {
    name: ELoanProductBrand.SBER_BUSINESS,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}sberBusiness`,
  },
  [ELoanProductBrand.POLE_RF]: {
    name: ELoanProductBrand.POLE_RF,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}poleRf`,
  },
  [ELoanProductBrand.DEMETRA]: {
    name: ELoanProductBrand.DEMETRA,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}demetra`,
  },
  [ELoanProductBrand.CENTER_INVEST]: {
    name: ELoanProductBrand.CENTER_INVEST,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}centerInvest`,
  },
  [ELoanProductBrand.ALFA]: {
    name: ELoanProductBrand.ALFA,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}alfa`,
  },
  [ELoanProductBrand.MANDARIN]: {
    name: ELoanProductBrand.MANDARIN,
    logo: `${LOAN_PRODUCT_BRAND_LOGO_FOLDER}mandarin`,
  },
}

export const LOAN_PRODUCTS: Partial<Record<ELoanProductName, ILoanProduct>> = {
  [ELoanProductName.BNPL_AGROINTEGRATOR]: {
    name: 'bnplAgrointegrator',
    offerType: EFinancingOfferType.BNPL_AGROINTEGRATOR,
    productId: EFinancingProductId.BNPL_AGROINTEGRATOR,
    gtmId: ELoanProductName.BNPL_AGROINTEGRATOR,
    badges: [FINANCING_PRODUCT_BADGE.highApprovalRate],
    conditions: {
      [EConditionLabel.INSTALLMENT_AMOUNT]: EConditionValue.UP_TO_25MLN,
      [EConditionLabel.INSTALLMENT_PERIOD]: EConditionValue.UP_TO_12M,
      [EConditionLabel.FUNDS_ISSUE]: EConditionValue.UP_TO_5WD,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.poleRf,
    url: EAgriculturalProductsRoute.main,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
    hasNoHeaderLink: true,
  },
  [ELoanProductName.SBERPAY]: {
    name: 'sberpay',
    offerType: EFinancingOfferType.SBERPAY,
    productId: EFinancingProductId.SBERPAY,
    gtmId: ELoanProductName.SBERPAY,
    badges: [FINANCING_PRODUCT_BADGE.threeMinutes],
    conditions: {
      [EConditionLabel.LOAN_AMOUNT]: EConditionValue.UP_TO_5MLN,
      [EConditionLabel.LOAN_PERIOD_V2]: EConditionValue.UP_TO_12M,
      [EConditionLabel.REGISTRATION_TIME]: EConditionValue.FROM_TREE_UNTIL_SEVEN,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.sberRassrochka,
    actionType: ELoanProductActionType.INSTALLMENT_LINK,
  },
  [ELoanProductName.EXPRESS_MFO]: {
    name: 'expressMfo',
    offerType: EFinancingOfferType.EXPRESS_MFO,
    productId: EFinancingProductId.EXPRESS_MFO,
    gtmId: ELoanProductName.EXPRESS_MFO,
    badges: [FINANCING_PRODUCT_BADGE.industrySolution],
    conditions: {
      [EConditionLabel.LOAN_AMOUNT]: EConditionValue.UP_TO_200MLN,
      [EConditionLabel.REPAYMENT_PROCEDURE]: EConditionValue.NO_MONTHLY_PAYMENTS,
      [EConditionLabel.LOAN_PERIOD]: EConditionValue.UP_TO_12M,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.poleRf,
    url: EFinancingOfferRoute.MFOPage,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.SBER_OBOROTKA_LOAN]: {
    name: 'sberOborotkaLoan',
    offerType: EFinancingOfferType.REVOLVING_LOAN_SBER,
    productId: EFinancingProductId.REVOLVING_LOAN_SBER,
    gtmId: ELoanProductName.SBER_OBOROTKA_LOAN,
    badges: [FINANCING_PRODUCT_BADGE.discountedRates],
    conditions: {
      [EConditionLabel.FROM_5MLN_SECURED]: EConditionValue.UP_TO_5MLN_UNSECURED,
      [EConditionLabel.SOFT_LOANS]: EConditionValue.UP_TO_7PCT,
      [EConditionLabel.COMMERCIAL_LOANS]: EConditionValue.FROM_13PCT,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.oborotSber,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.ADVANCE_FUNDING]: {
    name: 'advanceFunding',
    offerType: EFinancingOfferType.ADVANCE_PAYMENT,
    productId: EFinancingProductId.ADVANCE_FUNDING,
    gtmId: ELoanProductName.ADVANCE_FUNDING,
    badges: [],
    conditions: {
      [EConditionLabel.LOAN_AMOUNT]: EConditionValue.UP_TO_250MLN,
      [EConditionLabel.CULTURES]: EConditionValue.WHEAT,
      [EConditionLabel.LOAN_PERIOD]: EConditionValue.UP_TO_12M,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.demetra,
    url: EFinancingOfferRoute.advancePaymentOnFutureHarvest,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.SBER_INVESTMENT_LOAN]: {
    name: 'sberInvestmentLoan',
    offerType: EFinancingOfferType.SBER_INVESTMENT_LOAN,
    productId: EFinancingProductId.SBER_INVESTMENT_LOAN,
    gtmId: ELoanProductName.SBER_INVESTMENT_LOAN,
    badges: [FINANCING_PRODUCT_BADGE.discountedRates],
    conditions: {
      [EConditionLabel.FROM_5MLN_SECURED]: EConditionValue.UP_TO_5MLN_UNSECURED,
      [EConditionLabel.REVIEW_PERIOD]: EConditionValue.ABOUT_3WD,
      [EConditionLabel.LOAN_PERIOD]: EConditionValue.UP_TO_15Y,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.sberBusiness,
    url: EFinancingOfferRoute.investSber,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  [ELoanProductName.CENTER_INVEST_LOAN]: {
    name: 'centerInvestLoan',
    offerType: EFinancingOfferType.CENTER_INVEST_LOAN,
    productId: EFinancingProductId.CENTER_INVEST_LOAN,
    gtmId: ELoanProductName.CENTER_INVEST_LOAN,
    badges: [FINANCING_PRODUCT_BADGE.discountedRates],
    conditions: {
      [EConditionLabel.FROM_5MLN_SECURED]: EConditionValue.UP_TO_5MLN_UNSECURED,
      [EConditionLabel.SOFT_LOANS]: EConditionValue.UP_TO_7PCT,
      [EConditionLabel.LOAN_PERIOD]: EConditionValue.UP_TO_10Y,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.centerInvest,
    url: EFinancingOfferRoute.centerInvestLoan,
    actionType: ELoanProductActionType.COLLAPSING_BLOCK,
  },
  // Данный вид кредита временно отключен
  [ELoanProductName.LOMBARD_LOAN_ALFA_BANK]: {
    name: 'lombardLoanAlfaBank',
    offerType: EFinancingOfferType.LOMBARD_LOAN_ALFA_BANK,
    productId: EFinancingProductId.LOMBARD_LOAN_ALFA_BANK,
    gtmId: ELoanProductName.LOMBARD_LOAN_ALFA_BANK,
    badges: [FINANCING_PRODUCT_BADGE.discountedRates],
    conditions: {
      [EConditionLabel.LOAN_AMOUNT]: EConditionValue.UP_TO_50MLN,
      [EConditionLabel.AGE_OF_BUSINESS]: EConditionValue.ANY,
      [EConditionLabel.LOAN_PERIOD]: EConditionValue.UP_TO_10Y,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.alfa,
    url: EFinancingOfferRoute.lombardCreditAlfaBank,
    isHidden: true,
    actionType: ELoanProductActionType.ORDER_MODAL,
  },
  [ELoanProductName.BNPL_MANDARIN]: {
    name: 'bnplMandarin',
    offerType: EFinancingOfferType.BNPL_MANDARIN,
    productId: EFinancingProductId.BNPL_MANDARIN,
    gtmId: ELoanProductName.BNPL_MANDARIN,
    badges: [FINANCING_PRODUCT_BADGE.smallestOverpayment],
    conditions: {
      [EConditionLabel.INSTALLMENT_AMOUNT]: EConditionValue.UP_TO_1_2MLN,
      [EConditionLabel.INSTALLMENT_PERIOD]: EConditionValue.UP_TO_36M,
      [EConditionLabel.OVERPAYMENT]: EConditionValue.FROM_1_1PCT,
    },
    brand: LOAN_PRODUCT_BRAND_BY_NAME.mandarin,
    tooltip: messages.tooltip.mandarin,
    hasConsulting: true,
    actionType: ELoanProductActionType.ORDER_MODAL,
  },
}
