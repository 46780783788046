import type { FC } from 'react'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { openModal } from '@/components/composed/Modal/actions'
import { Button, Image } from '@/components/ui'
import { isAuthenticated as isUserAuthenticated, isUserAgrarian } from '@/logic/auth/reducer'
import { useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { ELoanProductActionType } from '@/pages/Financing/components/FinancingProducts/components/LoanProducts/types'
import type { RootState } from '@/redux/interfaces'
import { useRouterApi } from '@/router/hooks'
import { EAgriculturalProductsRoute, EFinancingProductId } from '@/types'
import { useOpenApplyingFinancingOfferModal } from '@/utils/hooks/applyingFinancingOffer/useOpenApplyingFinancingOfferModal'
import { useWebpExtension } from '@/utils/hooks/image'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { LoanProductTooltip } from '../LoanProductTooltip'
import { messages } from './messages'
import type { ILoanProductFooterProps } from './types'
import './styles.scss'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isAuthenticated: isUserAuthenticated()(state),
})

const LoanProductFooter: FC<ILoanProductFooterProps> = props => {
  const { product, isShowCollapsingBlock, setIsShowCollapsingBlock } = props

  const dispatch = useDispatch()
  const { isAuthenticated, isAgrarian } = useSelector(mapState)

  const history = useRouterApi()

  const { isMobile } = useDeviceType()

  const { productId, brand, url, offerType, gtmId, hasConsulting } = product
  const imageExt = useWebpExtension()
  const { pushGtmProduct } = useGtm()

  const isMandarin = productId === EFinancingProductId.BNPL_MANDARIN

  const { openApplyingFinancingOfferModal } = useOpenApplyingFinancingOfferModal({
    offerType,
    productId,
    gtmId,
  })

  const onClickButton = () => {
    pushGtmProduct(gtmId, EGtmLabel.ACTION)

    if (isMandarin) {
      history.push(EAgriculturalProductsRoute.main)

      return
    }

    openApplyingFinancingOfferModal(product.gtmId)
  }

  const onConsultClick = () => {
    dispatch(
      openModal({
        options: {
          dialogId: 'ConsultingFormModal',
        },
      }),
    )
  }

  const onClickLink = () => pushGtmProduct(gtmId, EGtmLabel.BUTTON)
  const onClickLogo = () => pushGtmProduct(gtmId, EGtmLabel.LOGO)
  const onClickInstallment = () => pushGtmProduct(gtmId, EGtmLabel.INSTALLMENT)

  const buttonText = isMandarin ? messages.installment : messages.apply

  const toogleCollapsing = () => {
    if (isShowCollapsingBlock) setIsShowCollapsingBlock(null)

    if (!isShowCollapsingBlock && productId) setIsShowCollapsingBlock(productId)
  }

  const actionsButton = () => {
    switch (product.actionType) {
      case ELoanProductActionType.INSTALLMENT_LINK:
        return (
          <CommonLink url={url} onClick={onClickInstallment}>
            <Button className="loanProductFooter__buttonWidth" modifiers={['green', 'full-width-always']}>
              {messages.installment}
            </Button>
          </CommonLink>
        )

      case ELoanProductActionType.COLLAPSING_BLOCK:
        return (
          <>
            <Button
              onClick={toogleCollapsing}
              className="loanProductFooter__buttonWidth"
              modifiers={['green', 'full-width-always']}
              iconProps={{
                name: isShowCollapsingBlock ? EIconName.ShevronUp : EIconName.ShevronDown,
                className: 'loanProductFooter__actionsIcon',
              }}
              iconPosition="right"
            >
              {messages.apply}
            </Button>

            {url && (
              <CommonLink className="loanProductFooter__link" url={url} onClick={onClickLink}>
                {messages.more}
              </CommonLink>
            )}
          </>
        )

      default:
        return (
          <>
            <Button
              modifiers={['green', 'full-width-always']}
              onClick={onClickButton}
              className="loanProductFooter__buttonWidth"
              isDisabled={!isAgrarian && isAuthenticated && !product.isAlwaysActiveButton}
            >
              {buttonText}
            </Button>

            {url && (
              <CommonLink className="loanProductFooter__link" url={url} onClick={onClickLink}>
                {messages.more}
              </CommonLink>
            )}

            {hasConsulting && (
              <div className="loanProductFooter__consult">
                <div className="loanProductFooter__consultArrow" />
                <CommonLink className="loanProductFooter__link" onClick={onConsultClick}>
                  {messages.consult}
                </CommonLink>
              </div>
            )}
          </>
        )
    }
  }

  return (
    <div className="loanProductFooter">
      <div className="loanProductFooter__actions">{actionsButton()}</div>

      {!isMobile && (
        <div className="loanProductFooter__logo">
          <CommonLink url={url} onClick={onClickLogo}>
            <Image className="loanProductFooter__brand" isInStatic src={`${brand.logo}.${imageExt}`} />
          </CommonLink>
          {product.tooltip && <LoanProductTooltip text={product.tooltip} />}
        </div>
      )}
    </div>
  )
}

export default memo(LoanProductFooter)
