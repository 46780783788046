import type { TMessages } from '@/types/TMessages'

export const messages = {
  bnplAgrointegrator: {
    title: 'Рассрочка с погашением в конце срока',
    description: 'Деньги за 3-5 рабочих дня под залог с/х земли, погашение после продажи урожая',
  },
  sberpay: {
    title: 'Онлайн-кредит для бизнеса на покупку товаров без залога',
    description: 'Деньги за 3 минуты без залога, погашение ежемесячно',
  },
  expressMfo: {
    title: 'Кредит для фермеров с платежом только в конце срока',
    description: 'Деньги за 5 рабочих дней без анализа финансовой отчетности, под залог земли',
  },
  sberOborotkaLoan: {
    title: 'Оборотный кредит на любые цели',
    description: 'Удобный кредит на любые цели с возможностью понижения процентной ставки',
  },
  advanceFunding: {
    title: 'Авансирование под урожай',
    description: 'Быстрое получение денежных средств на любые виды затрат',
  },
  sberInvestmentLoan: {
    title: 'Инвестиционный кредит',
    description: 'Сезонный график погашения. Можно использовать для погашения других кредитов',
  },
  lombardLoanAlfaBank: {
    title: 'Ломбардный кредит (под залог)',
    description: 'Деньги без анализа финансовых документов под залог с/х техники или недвижимости',
  },
  bnplMandarin: {
    title: 'Рассрочка без залога онлайн с одобрением до 70%',
    description: 'Рассрочка без первоначального взноса, залога и страховок, до 3 лет (36 мес)',
  },
  centerInvestLoan: {
    title: 'Кредиты для фермеров на любые бизнес цели',
    description: 'Деньги на любые цели даже для начинающего бизнеса с возможностью сезонного погашения',
  },
} satisfies TMessages
