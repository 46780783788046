import type { FC } from 'react'
import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { CustomizedForm } from '@/components/composed/forms'
import { useApplyingOfferFormSteps } from '@/components/composed/landingBlocks/deprecated/LandingApplyingOfferForm/useApplyingOfferFormSteps'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { FinancingConsultingModal } from '@/components/composed/modals'
import { Button, Icon } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { FormValuesFormatter, requestStatusName } from '@/constants/financing'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { applyFinancingOffer } from '@/logic/financing/actions'
import type { IFinancingOfferFormValues } from '@/logic/financing/interfaces'
import { pushGtmEventOnRouteAuthFromFormOrder, useGtm } from '@/logic/metrika/financing/gtmHelpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import { formattingMFOFinancingOffer } from '@/utils/formattingMFOFinancingOffer'
import { getApplyingFinancingOfferFormInitialValues } from '@/utils/getApplyingFinancingOfferFormInitialValues'
import { isApplyingOfferRequestBody } from '@/utils/hooks/applyingFinancingOffer/typeguard'
import { useApplyingFinancingOfferForm } from '@/utils/hooks/applyingFinancingOffer/useApplyingFinancingOfferForm'
import type { TFormInteractionHandler } from '@/utils/hooks/applyingFinancingOffer/useOpenApplyingFinancingOfferModal/types'
import { useRegionsMicrocreditOrganizations } from '@/utils/hooks/applyingFinancingOffer/useRegionsMicrocreditOrganizations'
import { ELoanProductCollapsing } from '../constants'
import { defaultFinancingOfferStepsAdapter } from './helpers'
import { messages } from './messages'
import type { ILoanProductOrderProps } from './types'
import './styles.scss'

addModalToWellKnown({ FinancingConsultingModal })

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const LoanProductOrder: FC<ILoanProductOrderProps> = props => {
  const { product, setIsActiveBlock } = props

  const { isAgrarian, userGtmInfo, userRegion } = useSelector(mapState)

  const productId = product.productId
  const offerType = product.offerType

  const { pushGtmFormInteraction } = useGtm()

  const onFormInteraction: TFormInteractionHandler = eventLabel => {
    if (product.gtmId && eventLabel) pushGtmFormInteraction(product.gtmId, eventLabel)
  }

  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const steps = useApplyingOfferFormSteps(offerType, isAuthenticated)
  const { dispatch, setOptions, formValues: values, profile } = useApplyingFinancingOfferForm({ productId, offerType })

  const { isMFOProduct, regionsAvailableExpressMFO } = useRegionsMicrocreditOrganizations({
    productId,
    offerType,
  })

  const shouldDisableButton = isAuthenticated && !isAgrarian

  const openConsultForm = () => setIsActiveBlock(ELoanProductCollapsing.CONSULT)

  const formattedSteps = useMemo(
    () => {
      const offerTypeRelatedSteps = defaultFinancingOfferStepsAdapter(steps, { dispatch, onFormInteraction }, openConsultForm)

      return offerTypeRelatedSteps
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps],
  )

  const formattedStepsRef = useRef(formattedSteps)

  useEffect(() => {
    formattedStepsRef.current = formattedSteps
  }, [formattedSteps])

  return (
    <div className="loanProductOrder">
      <h3 className="loanProductOrder__title">{messages.title}</h3>
      <div className="loanProductOrder__row">
        <div className="loanProductOrderInfo">
          <p>{messages.subtitle}</p>

          {isMFOProduct && (
            <div className="loanProductOrderCalc">
              <p className="loanProductOrderCalc__heading">{messages.calc.heading}</p>
              <Button
                className="loanProductOrderCalc__action"
                modifiers={['outline-gray']}
                iconProps={{
                  name: EIconName.ShevronRight,
                  className: 'loanProductFooter__actionsIcon',
                }}
                iconPosition="right"
                onClick={() => setIsActiveBlock(ELoanProductCollapsing.CALC)}
              >
                <Icon className="loanProductOrderCalc__actionIcon" name={EIconName.Calculator} />
                {messages.calc.action}
              </Button>
            </div>
          )}
        </div>

        <div className="loanProductOrderForm">
          <CustomizedForm<IFinancingOfferFormValues>
            initialValues={getApplyingFinancingOfferFormInitialValues({ offerType, productId, profile, formValues: values })}
            onSubmit={formValues => {
              const body = FormValuesFormatter[offerType]?.(formValues) || formValues

              if (isAuthenticated) {
                if (isApplyingOfferRequestBody(body)) {
                  if (isMFOProduct) {
                    formattingMFOFinancingOffer({ regionsAvailableExpressMFO, dispatch, body })
                  } else dispatch(applyFinancingOffer({ body, offerType }))
                }
              } else {
                setOptions({ formValues: {} })
                pushGtmEventOnRouteAuthFromFormOrder(userGtmInfo, userRegion.name, null, productId)

                openAuthModal({
                  shouldSendCodeImmediately: true,
                  initialValues: { phone: formValues.phone, email: formValues.email },
                  onCloseDialog: () => {
                    setOptions({ formValues })
                  },
                  onCloseModal: () => {
                    if (isApplyingOfferRequestBody(body)) {
                      dispatch(applyFinancingOffer({ body, offerType, isLost: true }))
                    }
                  },
                })
              }
            }}
            steps={formattedStepsRef.current}
            requestStatusName={requestStatusName}
            shouldDisableButton={shouldDisableButton}
          />
        </div>
      </div>
    </div>
  )
}
